<template>
    <v-container grid-list-xl fluid>
        <v-layout wrap>
            <v-flex xs12>
                <h1>Devolução</h1>
                <v-divider class=" mb-2" />
            </v-flex>
        </v-layout>

        <v-layout wrap v-if="step >= 1">
            <v-flex xs12>
                <div class="subtitle-1" v-if="item.code"><strong>Código:</strong> {{ item.code }}</div>
                <div class="subtitle-1"><strong>Loja:</strong> {{ storeName }}</div>
                <div class="subtitle-1" v-if="hasDriver"><strong>Motorista:</strong> {{ driverCollectPackName }}</div>
            </v-flex>
        </v-layout>

        <v-layout wrap v-if="step == null">
            <StoreList labelNotFound="Não foi localizado nenhuma CD." labelSelect="Selecione abaixo o CD que você está sendo feito a devolução:" endpoint="checklists/return/stores"
                @selected="start" />

        </v-layout>

        <v-layout wrap v-else-if="step == 1">
            <v-flex md6 offset-md3 xs12 v-if="externalDriver == false">
                <v-btn @click="externalDriver = true" color="warning" class="mt-12" width="100%"> <v-icon left>connect_without_contact</v-icon>Motorista tercerizado </v-btn>
            </v-flex>

            <v-flex md6 offset-md3 xs12 v-if="externalDriver == true">
                <v-text-field :label="$t('Nome do motorista')" v-model="item.driverCollectPackExternal" :rules="[rules.required, rules.min(5)]" clearable
                    v-on:keyup.enter="selectExternalUser" />
                <v-btn @click="selectExternalUser" color="success" class="mt-12" width="100%"> <v-icon left>navigate_next</v-icon>Próximo</v-btn>
            </v-flex>
            <UserList endpoint="checklists/return/users" @selected="selectUser" v-else />
        </v-layout>

        <v-layout wrap v-else-if="step == 2">
            <v-flex xs12>
                <v-form ref="formStep2" @submit.prevent="startBip">
                    <v-text-field :label="$t('Digite o número de pedidos')" v-model="item.ordersNumber" :rules="[rules.required, rules.range(1, 1000)]" type=" number" clearable
                        v-on:keyup.enter="startBip" ref="ordersNumberField" />
                </v-form>
            </v-flex>
            <v-flex xs12>
                <v-btn color="success" :disabled="item.ordersNumber == null" @click="startBip" class="mx-auto" width="100%">
                    Iniciar Bipagem
                </v-btn>
            </v-flex>
        </v-layout>

        <v-layout wrap v-else-if="step == 3">
            <v-flex xs12 sm6 md6 class="pa-0 pb-4">
                <v-card :color="reachedGoal ? 'success' : 'grey'">
                    <v-card-text class="white--text">
                        <div class="headline mb-2">
                            <v-icon dark>luggage</v-icon>
                            {{ item.ordersNumber }}
                        </div>
                        Número de pedidos informado
                    </v-card-text>
                </v-card>
            </v-flex>
            <v-flex xs12>
                <v-btn @click="showQRCode = true" color="success" class="mt-12" width="100%"> <v-icon left>qr_code_scanner</v-icon>Bipar Pedido </v-btn>
            </v-flex>
            <v-flex xs12 mt-12>
                <v-btn color="error" @click="finishStep1" class="mx-auto" width="100%">Encerrar bipagem</v-btn>
            </v-flex>
        </v-layout>

        <BarCode :show="showQRCode" @close="showQRCode = false" manual @onRead="readedQRCode" />
        <ConfirmModal confirmText="Confirmar" cancelText="Cancelar" ref="confirm" />

        <v-dialog :value="messageFeedBackBeep.msg != null" max-width="500" persistent>
            <v-card :color="messageFeedBackBeep.color" :loading="messageFeedBackBeep.timer > 0">
                <template slot="progress">
                    <v-progress-linear height="10" color="white"
                        :value="messageFeedBackBeep.timerNow > 0 ? ((messageFeedBackBeep.timerNow / messageFeedBackBeep.timer) * 100) : 0" />
                </template>
                <v-card-text class="text-center pt-8">
                    <v-icon x-large color="white">message</v-icon>
                </v-card-text>
                <v-card-text class="headline white--text">{{ messageFeedBackBeep.msg }}</v-card-text>
                <v-card-text class="white--text">{{ $t("Código lido:") }} {{ codeBippedToCheck }}</v-card-text>

                <v-card-actions class="justify-end">
                    <v-btn @click="closeBeepFeedback" dark small outlined>{{ $t("Fechar") }}</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

    </v-container>
</template>

<script>
import rules from "@/helpers/rules";
import ConfirmModal from "@/components/core/ConfirmModal";
import BarCode from "@/components/core/BarCode";
import StoreList from "@/components/shared/StoreList";
import UserList from "@/components/shared/UserList";

const itemClean = {
    extraCode: null,
    ordersNumber: null,
    store: null,
    driverCollectPack: null,
    driverCollectPackExternal: null,
};

export default {
    components: { ConfirmModal, BarCode, StoreList, UserList },

    data() {
        return {
            rules,
            loaded: false,
            filterName: null,
            showQRCode: false,
            messageFeedBackBeep: {
                msg: null,
                color: null,
                timer: null,
                timerNow: null
            },
            storeName: null,
            externalDriver: false,
            driverCollectPackName: null,
            timer: null,
            codeBippedToCheck: null,
            step: null,
            item: JSON.parse(JSON.stringify(itemClean)),
            finishResult: null,
        };
    },

    computed: {
        hasDriver() {
            return this.item.driverCollectPack || this.item.driverCollectPackExternal;
        },
        reachedGoal() {
            return this.item && this.item.count >= this.item.ordersNumber;
        },
        inGoal() {
            return this.item && this.item.count == this.item.ordersNumber;
        },
        packagesBipped() {
            return this.item.ordersChecked ? this.item.ordersChecked.length : 0;
        },
        ordersBipped() {
            let a = this.item.ordersChecked;
            let uniqueArray = a.filter(function (item, pos) {
                return a.indexOf(item) == pos;
            });
            return uniqueArray.length;
        },
    },

    methods: {
        readedQRCode(QRCode) {
            this.codeBippedToCheck = QRCode;
            this.bip();
        },
        start(store) {
            this.finishResult = null;
            this.storeName = store.name;
            this.item = JSON.parse(JSON.stringify(itemClean));
            this.item.store = store.id;
            this.step = 1;
        },
        selectUser(user) {
            this.driverCollectPackName = user.name;
            this.item.driverCollectPack = user.id;
            this.step = 2;
            this.$nextTick(() => this.$refs.ordersNumberField.focus());
        },
        selectExternalUser() {
            if (this.item.driverCollectPackExternal && this.item.driverCollectPackExternal.length >= 5) {
                this.driverCollectPackName = this.item.driverCollectPackExternal;
                this.step = 2;
                this.$nextTick(() => this.$refs.ordersNumberField.focus());
            } else {
                this.$eventHub.$emit("msgError", this.$t("Digite o nome do motorista"));
            }
        },
        startBip() {
            if (this.$refs.formStep2.validate())
                this.$http
                    .post(`checklists/return/create`, this.item)
                    .then((result) => {
                        if (result) {
                            this.item = result;
                            this.step = 3;
                        } else {
                            this.$eventHub.$emit("msgError", this.$t("Erro ao criar um romaneio"));
                        }
                    })
                    .catch((error) => {
                        this.$eventHub.$emit("msgError", error.message ? error.message : this.$t("Erro ao criar um romaneio"));
                    });
        },
        closeBeepFeedback() {
            this.messageFeedBackBeep.msg = null;
            this.messageFeedBackBeep.timerNow = null;
            this.messageFeedBackBeep.timer = null;
            if (this.inGoal) {
                this.$eventHub.$emit("msgSuccess", this.$t("Já foram bipados o número de pacotes informado."));
            } else {
                this.showQRCode = true;
            }
            if (this.timer) {
                clearInterval(this.timer);
            }
        },
        timerTick() {
            if (this.timer) {
                clearInterval(this.timer);
            }
            this.timer = setInterval(() => {
                if (this.messageFeedBackBeep.timerNow > 0) {
                    this.messageFeedBackBeep.timerNow--;
                } else {
                    this.closeBeepFeedback();
                }
            }, 10);
        },
        async bip() {
            this.showQRCode = false;
            this.messageFeedBackBeep = { msg: "Conferindo...", color: "primary", timerNow: 10000, timer: 10000 };

            if (this.codeBippedToCheck == null) {
                this.messageFeedBackBeep = { msg: "Código inválido.", color: "error", timerNow: 500, timer: 500 };
            } else {
                await this.$http
                    .patch(`checklists/return/bip/${this.item.code}`, { data: { store: this.item.store, ticket: this.codeBippedToCheck } })
                    .then((result) => {
                        if (result) {
                            if (result.created == true) {
                                this.messageFeedBackBeep = { msg: "Pacote localizado e registrado, porém não foi localizada a coleta.", color: "warning", timerNow: 500, timer: 500 };
                            } else if (result.extra && result.extra.expiredReturn == true) {
                                this.messageFeedBackBeep = { msg: "Pacote devolvido com sucesso. O mesmo já foi coletado a mais de 4 dias e deve ser devolvido e não mais ser feito tentativa de entrega.", color: "error", timerNow: 1000, timer: 1000 };
                            } else {
                                this.messageFeedBackBeep = { msg: "Pedido localizado com sucesso.", color: "success", timerNow: 200, timer: 200 };
                            }
                            if (result.checklist) {
                                this.item = result.checklist;
                            }
                        } else {
                            this.messageFeedBackBeep = { msg: "Pedido não localizado", color: "error", timerNow: 500, timer: 500 };
                        }
                    })
                    .catch((error) => {
                        this.messageFeedBackBeep = { msg: error.message ? error.message : this.$t("Erro ao localizar pedido"), color: "error", timerNow: 500, timer: 500 };
                    });
            }
            this.timerTick();
        },
        restartScreen() {
            this.loaded = false;
            this.showQRCode = false;
            this.codeBippedToCheck = null;
            this.step = null;
            this.item = JSON.parse(JSON.stringify(itemClean));
            this.finishResult = null;
            // this.getStores();
        },
        updateOrder() {
            this.$http
                .patch(`checklists/return/update/${this.item.code}`, { data: { extraCode: this.item.extraCode } })
                .then((result) => {
                    if (result) {
                        this.restartScreen();
                    } else {
                        this.$eventHub.$emit("msgError", this.$t("Erro ao atualizar dados do romaneio."));
                    }
                })
                .catch((error) => {
                    this.$eventHub.$emit("msgError", error.message ? error.message : this.$t("Erro ao atualizar dados do romaneio."));

                });
        },
        finishStep1() {
            this.$refs.confirm.open("", "Deseja mesmo encerrar a devolução?").then((confirm) => {
                if (confirm) {
                    this.$http
                        .patch(`checklists/return/finish/${this.item.code}`)
                        .then(() => {
                            this.restartScreen();
                        })
                        .catch((error) => {
                            this.$eventHub.$emit("msgError", error.message ? error.message : this.$t("Erro ao atualizar dados do romaneio."));
                        });
                }
            });
        },
    },
};
</script>